// schemas.js

import { schema } from 'normalizr';

const userSchema = new schema.Entity('users', {}, {
  idAttribute: '_id'
});

const folderSchema = new schema.Entity('folders', {}, { idAttribute: '_id' });

const commentSchema = new schema.Entity('comments', {}, { idAttribute: '_id' });

const todoSchema = new schema.Entity('todos', {
  comments: [commentSchema]
}, { 
  idAttribute: '_id' 
});

const todoCategorySchema = new schema.Entity('todoCategories', {
  tasks: [todoSchema]
}, { 
  idAttribute: '_id'
});

const spaceSchema = new schema.Entity('spaces', {
  members: [userSchema],
  admins: [userSchema],
  pendingUsers: [userSchema],
  folders: [folderSchema],
  taskCategories: [todoCategorySchema]
}, {
  idAttribute: '_id'
});

const fileSchema = new schema.Entity('files', {
  comments: [commentSchema]
}, {
  idAttribute: '_id'
});

const notificationSchema = new schema.Entity('notifications', {}, {
  idAttribute: '_id'
});

export { userSchema, spaceSchema, commentSchema, folderSchema, fileSchema, todoCategorySchema, todoSchema, notificationSchema};