/* global process */

const config = {
  API_BASE_URL: process.env.API_BASE_URL || "https://workspace-api.counter.app",
  WORKSPACE_URL: process.env.WORKSPACE_URL || "https://workspace.counter.app",
  TITLE: process.env.TITLE || "test title",
  SENTRY_DSN: process.env.SENTRY_DSN,
  SENTRY_SAMPLE_RATE: process.env.SENTRY_SAMPLE_RATE || 1,
  NODE_ENV: process.env.NODE_ENV,
  SENTRY_ENVIRONMENT: process.env.SENTRY_ENVIRONMENT || process.env.NODE_ENV,
  MAIL_SUPPORT: process.env.MAIL_SUPPORT || "support@hostelworld.com",
  TEL_SUPPORT: process.env.TEL_SUPPORT || "35315245888",
  SUPPORT_PLATFORM:
    process.env.SUPPORT_PLATFORM ||
    "https://support.hostelworld.com/counter-help-center",
  INTERCOM_PRODUCT_TOUR: process.env.INTERCOM_PRODUCT_TOUR, // Is it used ?
  IMAGES_CDN_PATH: process.env.IMAGES_CDN_PATH,
  TEST_ENV_VAR: process.env.TEST_ENV_VAR, // Is it used ?
  STRIPE_PUBLISHABLE_KEY: process.env.STRIPE_PUBLISHABLE_KEY,
  STRIPE_PLUS_YEARLY_PRICE_ID: process.env.STRIPE_PLUS_YEARLY_PRICE_ID,
  STRIPE_PLUS_MONTHLY_PRICE_ID: process.env.STRIPE_PLUS_MONTHLY_PRICE_ID,
  STRIPE_PRO_YEARLY_PRICE_ID: process.env.STRIPE_PRO_YEARLY_PRICE_ID,
  STRIPE_PRO_MONTHLY_PRICE_ID: process.env.STRIPE_PRO_MONTHLY_PRICE_ID,
  STRIPE_PLUS_PRODUCT_ID: process.env.STRIPE_PLUS_PRODUCT_ID,
  STRIPE_PLUS_YEARLY_PRODUCT_ID: process.env.STRIPE_PLUS_YEARLY_PRODUCT_ID,
  STRIPE_PLUS_MONTHLY_PRODUCT_ID: process.env.STRIPE_PLUS_MONTHLY_PRODUCT_ID,
  BOOKING_ENGINE_URI: process.env.BOOKING_ENGINE_URI,
  MAPBOX_ACCESS_TOKEN: process.env.MAPBOX_ACCESS_TOKEN, // Is it used ?
  GOOGLE_MAPS_KEY: process.env.GOOGLE_MAPS_KEY, // Is it used ?
  FRONTDESK_URI: process.env.FRONTDESK_URI || "https://frontdesk.counter.app",
  CHARGEBEE_CB_SITE: process.env.CHARGEBEE_CB_SITE,
  PUSHER_KEY: process.env.PUSHER_KEY,
  S3_PATH: process.env.S3_PATH,
  HELP_PAGE_LINK:
    process.env.HELP_PAGE_LINK ||
    "https://support.hostelworld.com/knowledge/counter-pms",
  ROOM_TYPE_VALIDITY_IN_YEARS: process.env.ROOM_TYPE_VALIDITY_IN_YEARS || 2,
  HOTJAR_ID: process.env.HOTJAR_ID || 3160242,
  HOTJAR_SV: process.env.HOTJAR_SV || 6,
  HOTJAR_DEBUG: process.env.HOTJAR_DEBUG || false,
  ENABLE_DAC7: process.env.ENABLE_DAC7 || false,
  HHL_HOST: process.env.HHL_HOST || "https://hhl.hostelworld.com",
};

module.exports = config;
