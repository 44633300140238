import React, { useState } from "react";

import Box from "@material-ui/core/Box";
import { ReportProblem, Close } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    background: "red",
    width: "100%",
    heigth: "auto",
    position: "absolute",
    zIndex: 1111,
    top: 0,
    left: 0,
    padding: "10px 0",
  },
  boxWrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    width: "90%",
    color: "#fff",
    fontWeight: 600,
  },
  icon: {
    margin: "10px 10px",
    cursor: "pointer",
    color: "#fff",
  },
});

const AlertBanner = ({ banner, closeBanner }) => {
  const classes = useStyles();
  return (
    <>
      {banner ? (
        <div
          className={classes.root}
          style={{
            display: `${banner ? "block" : "hidden"}`,
          }}
        >
          <Box className={classes.boxWrapper}>
            <Box className={classes.icon}>
              <ReportProblem />
            </Box>
            <Box className={classes.text}>{banner?.text}</Box>
            <Box className={classes.icon}>
              <Close onClick={() => closeBanner()} />
            </Box>
          </Box>
        </div>
      ) : null}
    </>
  );
};

export default AlertBanner;
