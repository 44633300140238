import React from 'react';
import {BrowserRouter, Route, Switch } from 'react-router-dom';
import { MuiThemeProvider, createTheme, withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import jwt_decode from "jwt-decode";
import setAuthToken from "../redux/utils/setAuthToken";
import { logoutUser } from "../redux/actions/auth/auth";

import PrivateRoute from "../components/private-route/PrivateRoute";
import Signup from '../components/signupCommunity/Signup';
import AppContainer from "../containers/AppContainer";
import Login from "../components/auth/LoginPage";
import NewUserPage from '../components/user/NewUserPage';
import ResetPasswordPage from '../components/auth/ResetPasswordPage';
import PMSTermsPage from '../components/terms/PMSTermsPage';
import PlusTermsPage from '../components/terms/PlusTermsPage';
import HWExperiencesContainer from '../containers/HWExperiencesContainer';
import HWMigration from '../containers/HWMigrationContainer';
import ScheduleCallContainer from '../containers/ScheduleCallContainer';

import QuickBooksRedirect from '../components/quickBooks/QuickBooksRedirect';

const theme = createTheme({
    palette: {
      primary: {
        light: "#ff8e8c",
        main: "#1A428A",
        dark: "#1A428A",
        contrastText: "#fff"
      },
      secondary: {
        light: "#F8F9FB",
        main: "#999999",
        dark: "#E37381",
        contrastText: "#000"
      },
      success: {
        main: "#5aac44"
      }
    },
    typography: {
      fontFamily: "Roboto, sans-serif",
      htmlFontSize: 10,
      textTransform: "none",
      color: "#484848",
      button: {
        textTransform: "none",
        cursor:"pointer"
      },
      subtitle1: {
        fontWeight: 600,
        color: "#484848",
        fontSize: '1.6rem'
      },
      body1: {
        fontSize: '1.4rem'
      },
      body2: {
        fontSize: '1.2rem'
      },
      h3: {
        fontFamily: "Roboto, sans-serif",
        fontSize: '2.4rem',
        fontWeight: 600,
        color: "#444444",
        lineHeight: 1.4
      },
      h4: {
        fontFamily: "Roboto, sans-serif",
        fontSize: '1.8rem',
        fontWeight: 600,
        color: "#444444"
      },
      h5: {
        fontSize: '1.6rem',
        color: "#444444"
      },
      h6: {
        fontSize: '1.4rem',
        color: "#484848"
      },
      h2: {
        fontFamily: "Roboto, sans-serif",
        fontWeight: 600,
        fontSize: '3rem',
        lineHeight: 1.3
      },
      h1: {
        fontFamily: "Roboto, sans-serif",
        fontWeight: 600,
        fontSize: '3.6rem',
        lineHeight: 1.3,
      }
    }
});

const styles = theme => ({
  footer: {
    width: "100%"
  }
});

// Check for token to keep user logged in
if (localStorage.jwtToken) {
  
  // Set auth token header auth
  const token = localStorage.jwtToken;
  setAuthToken(token);
  
  // Decode token and get user info and exp
  const decoded = jwt_decode(token);
  const currentTime = Date.now() / 1000; // to get in milliseconds
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());    // Redirect to login
    window.location.href = "./login";
  }
}

class AppRouter extends React.Component {
  

  render() {
    const {classes} = this.props;

    return (
      <BrowserRouter>
        <MuiThemeProvider theme={theme}>
          <Switch>
            {/* <Route path="/" render={()=> <App />} exact={true} /> */}
            <Route exact path="/login" component={Login} />
            <Route exact path="/signup" component={Signup} />
            <Route path="/join" component={NewUserPage} />
            <Route path="/forgotPassword" component={Login} />
            <Route path="/resetPassword" component={ResetPasswordPage} />
            <Route exact path="/pms-terms" component={PMSTermsPage} />
            <Route path="/plus-terms" component={PlusTermsPage} />
            <Route path="/experiences" component={HWExperiencesContainer} />
            <Route exact path="/migration" component={HWMigration} />
            <PrivateRoute exact path="/" component={AppContainer} />
            
            <PrivateRoute exact path="/schedule-call" component={ScheduleCallContainer} />
            <PrivateRoute path="/quickBooks/callback" component={QuickBooksRedirect} />
            <PrivateRoute path="/:propCode/reservation/:reservationCode" component={AppContainer} exact={true}/>
            <PrivateRoute path="/" component={AppContainer} />
          </Switch>
        </MuiThemeProvider>
      </BrowserRouter>
    )
  };
}

AppRouter.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(connect()(AppRouter));


